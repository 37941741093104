import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import DefaultLayout from '../layouts/Default';
import Seo from '../components/global/Seo';

export default function PrivacyPolicy({ data }) {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <DefaultLayout>
      <Seo title={title} />

      <PrivacySection>
        <div className='section-inner'>
          <PrivacyContentWrap dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </PrivacySection>
    </DefaultLayout>
  );
}

const PrivacySection = styled.section`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  gap: 5rem;
`;

const PrivacyContentWrap = styled.article`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const privacy = graphql`
  query Privacy {
    markdownRemark(fileAbsolutePath: { regex: "/(misc)/" }) {
      frontmatter {
        title
      }
      html
    }
  }
`;
